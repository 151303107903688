/* icon styles */

.Introduction a {
  color: var(--icon-color);
}

.Introduction li {
  padding-top: 16px;
}

.Introduction a:hover {
  color: var(--primary-color);
  transition: all 200ms ease-in-out;
}

.contact-icons {
  font-size: 20px;
  list-style: none;
  padding: 0px;
  margin: 0;
  position: relative;
  left: 26px;
  top: 176px;
  z-index: 100;
}

/* text styles */

.introduction-text h1 {
  color: var(--primary-color);
  margin-bottom: 20px;
  margin-top: 40px;
  font-family: var(--titles-font);
  font-size: 76px;
}

.introduction-text h2 {
  color: var(--paragraph-color);
  font-family: var(--paragraph-font);
  font-weight: 300;
  font-size: 20px;
  letter-spacing: 2px;
}

.cta {
  color: var(--primary-color);
  font-family: var(--paragraph-font);
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 2px;
  margin-top: 140px;
}

.vertical-line {
  border-left: 1px solid var(--primary-color);
  display: inline-block;
  position: relative;
  top: 20px;
  height: 200px;
}

/* responsive */

@media (max-width: 960px) {
  .introduction-text h1 {
    font-size: 52px;
    margin-top: 72px;
  }

  .introduction-text h2 {
    font-size: 16px;
    margin-top: -8px;
    line-height: 150%;
  }

  .contact-icons {
    display: none;
  }

  .cta {
    font-size: 12px;
    margin-top: 36px;
  }

  .vertical-line {
    height: 100px;
  }
}
