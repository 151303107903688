.Projects .projects-heading {
  margin-bottom: 56px;
  margin-top: 192px;
}

.Projects h3 {
  color: var(--primary-color);
  font-family: var(--titles-font);
  font-size: 36px;
  position: relative;
}

.Projects .number {
  font-size: 48px;
}

.project-image {
  display: block;
  margin: 0 auto;
  width: 500px;
  border-radius: 4px;
  position: relative;
  bottom: 28px;
  transform: scale(1);
  transition: all 0.3s;
  z-index: -1;
}

.project:hover .project-image {
  transform: scale(1.1);
}

.Projects .project-button {
  display: block;
  margin: 0 auto;
  padding-bottom: 0px;
  text-align: center;
}

.Projects .project-button:focus {
  box-shadow: none;
}

.project-title::before {
  background: var(--primary-color);
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  right: 50%;
  transform-origin: right;
  transform: rotate(90deg);
  width: 68px;
}

.project-title::after {
  content: "";
  position: absolute;
  left: 5px;
  top: 28px;
  width: 100%;
  height: 20px;
  background: radial-gradient(
    circle,
    rgba(231, 158, 133, 0.2) 62%,
    rgba(251, 245, 241, 1) 100%
  );
  border-radius: 0.8em 0.3em;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 250ms ease-in;
  z-index: -1;
}

.project:hover .project-title:after {
  transform: scaleX(1);
}

.Projects p {
  color: var(--paragraph-color);
  font-family: var(--paragraph-font);
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 200%;
  margin-top: 20px;
}

/* responsive */

@media (max-width: 960px) {
  .Projects .projects-heading {
    margin-top: 72px;
  }
}
