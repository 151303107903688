.AboutMe {
  margin-top: 116px;
}

/* left column styles */

.image-email {
  background-image: url(/public/images/spots.svg);
  background-repeat: no-repeat;
  background-size: 380px;
}

.email-link {
  color: var(--primary-color);
  font-family: var(--titles-font);
  letter-spacing: 2px;
  text-decoration: none;
  transform: rotate(-90deg);
}

.email-link:hover {
  color: var(--icon-color);
  transition: all 200ms ease-in-out;
}

.email-link::after {
  background: var(--primary-color);
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  top: 160px;
  right: 50%;
  transform-origin: right;
  transform: rotate(90deg);
  width: 116px;
}

.about-img {
  width: 100%;
  width: 500px;
  z-index: -1;
}

.about-img,
.email-link {
  position: relative;
  left: -120px;
  top: 140px;
}

/* right column styles */

.about-title {
  position: relative;
  top: 36px;
}

.about-text {
  background: var(--background-color);
  font-family: var(--paragraph-font);
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 200%;
  padding: 64px;
  padding-bottom: 20px;
}

.about-cta {
  color: var(--primary-color) !important;
  font-family: var(--paragraph-font);
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 2px;
  margin-top: 24px;
  margin-bottom: 0px;
}

.arrow {
  animation: bounce 2s infinite;
  display: inline-block;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

/* responsive */

@media (max-width: 1180px) {
  .AboutMe {
    margin-top: 72px;
  }

  .image-email {
    background: none;
  }

  .email-link {
    display: none;
  }

  .about-img {
    margin: 0 auto;
    left: 0;
    top: 0;
  }

  .about-text {
    padding: 44px;
  }
}

@media (max-width: 540px) {
  .about-img {
    left: 0;
    top: 0;
  }
}

@media (max-width: 280px) {
  .about-text {
    padding: 28px;
  }

  .about-button {
    bottom: 0px;
    margin-bottom: 64px;
  }
}
