h4 {
  color: var(--primary-color);
  font-family: var(--titles-font);
}

.modal-icons {
  color: var(--primary-color);
  font-size: 20px;
  padding-right: 12px;
}

.modal-icons:hover {
  color: var(--icon-color);
  transition: all 200ms ease-in-out;
}

.modal-body .modal-text {
  font-size: 14px;
}

.close-btn {
  background-color: var(--primary-color) !important;
  border: none !important;
  box-shadow: none !important;
  font-family: var(--paragraph-font);
  font-size: 14px !important;
  letter-spacing: 1px;
}

.btn-close:focus {
  outline: none !important;
  box-shadow: none;
}
