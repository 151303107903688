.Navbar .navbar {
  background-color: transparent !important;
  font-family: var(--paragraph-font);
}

.Navbar .container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}

.Navbar .navbar-brand {
  margin: 0px;
}

/* navbar items */

.Navbar .logo {
  color: var(--primary-color);
  max-width: 60px;
}

.nav-item {
  padding-right: 64px;
}

.about,
.projects,
.contact,
.resume {
  color: var(--primary-color) !important;
  font-size: 14px;
  letter-spacing: 2px;
  position: relative;
}

/* hover effects */

.about::after,
.projects::after,
.contact::after,
.resume::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 11px;
  width: 100%;
  height: 8px;
  background: radial-gradient(
    circle,
    rgba(231, 158, 133, 0.2) 62%,
    rgba(251, 245, 241, 1) 100%
  );
  border-radius: 0.8em 0.3em;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 250ms ease-in;
  z-index: -1;
}

.about:hover::after,
.projects:hover::after,
.contact:hover::after,
.resume:hover::after {
  transform: scaleX(1);
}

/* mobile */

.navbar-toggler {
  border-color: none;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}
