.Footer {
  background-color: var(--background-color);
  padding-top: 59px;
  padding-bottom: 37px;
  margin-top: -112px;
}

.Footer a {
  color: var(--primary-color);
  font-family: var(--titles-font);
  font-size: 16px;
  letter-spacing: 2px;
  margin-top: 31px;
  text-decoration: none;
}

.Footer a:hover {
  color: var(--icon-color);
  transition: all 200ms ease-in-out;
}

.Footer ul {
  padding: 0px;
  margin-top: 36px;
}

.Footer li {
  color: var(--primary-color);
  padding-right: 24px;
  display: inline;
}

.background-image {
  background-image: url(/public/images/spots.svg);
  background-position: left;
  background-repeat: no-repeat;
  height: 280px;
  position: relative;
  top: 68px;
}

/* responsive */

@media (max-width: 960px) {
  .background-image {
    display: none;
  }

  .Footer {
    margin-top: 60px;
  }
}
