:root {
  --titles-font: "Playfair Display", serif;
  --paragraph-font: "Raleway", sans-serif;
  --paragraph-color: #3d3d3d;
  --primary-color: #bb5a5a;
  --background-color: rgba(234, 206, 180, 0.2);
  --icon-color: #e79e85;
}

/* global titles */

.title {
  color: var(--primary-color);
  font-family: var(--titles-font);
  font-size: 64px;
}

.App footer {
  background-image: url(/public/images/spots.svg);
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 300px;
}

.App .signature {
  color: #5e5d5d;
  font-family: var(--paragraph-font);
  font-size: 12px;
  letter-spacing: 2px;
}

.signature a {
  color: var(--primary-color);
  text-decoration: none;
}

.signature a:hover {
  color: var(--icon-color);
  transition: all 200ms ease-in-out;
}

/* hero styles & animation */

.main-container {
  padding-left: 55px !important;
  padding-right: 55px !important;
}

.hero {
  background-image: url(/public/images/hero-bgrd.jpg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.hero {
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* responsive */

@media (max-width: 960px) {
  .main-container {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }
}
